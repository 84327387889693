<template>
    <Toast position="top-right" />
    <div id="nav">
        <a href="https://dmusport.dk/road-racing/road-racing-reglement/" target="_blank">Regulations</a> | <a href="https://dmusport.dk/road-racing/classic-road-race/" target="_blank">Get started</a>
    </div>
    <router-view />
</template>

<style lang="scss">
body {
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px 30px 0 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
        color: #42b983;
        }
    }
}
</style>
