import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { QuillEditor } from '@vueup/vue-quill'

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';
import Paginator from 'primevue/paginator';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

import '@vueup/vue-quill/dist/vue-quill.snow.css';

import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

let app = createApp(App)

app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.component('QuillEditor', QuillEditor);

app.component('Button', Button);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('Checkbox', Checkbox);
app.component('InputText', InputText);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Paginator', Paginator);
app.component('Toast', Toast);

app.mount('#app');